var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-0 ma-0",attrs:{"width":"100%","tile":"","height":"100%"}},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":"","fill-height":""}},[_c('v-row',{staticClass:"fill-height",attrs:{"align":"stretch"}},[(!_vm.$vuetify.breakpoint.mobile)?_c('v-col',{staticClass:"text-center pa-0",attrs:{"cols":"12","md":"5","order":"2","order-md":"2"}},[_c('v-card',{staticClass:"pa-10 mx-auto",attrs:{"color":"primary","tile":"","height":"100%","flat":"","dark":""}},[_c('v-row',{staticClass:"fill-height ma-0",attrs:{"justify":"center"}},[_c('v-col',[_c('h1',[_vm._v("Gestão de Privacidade e Segurança de Dados")]),_c('h3',[_vm._v(" Adeque sua empresa à Lei Geral de Proteção de Dados (LGPD) ")]),_c('img',{staticStyle:{"max-width":"100%"},attrs:{"src":require("@/assets/lgpd_digisystem_ibm.png")}})])],1)],1)],1):_vm._e(),_c('v-col',[_c('v-row',{staticClass:"fill-height",attrs:{"justify":"center"}},[_c('v-col',[_c('div',{staticClass:"px-10"},[_c('div',{staticClass:"text-center pa-5"},[_c('img',{staticStyle:{"max-width":"80%"},attrs:{"src":require("@/assets/logo.png"),"width":"400px"}})]),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"12","lg":"10","xl":"10","xxl":"10"}},[_c('v-card-title',{staticClass:"text-center"},[_c('h2',{staticClass:"headline"},[_vm._v("Recuperação de Senha")])]),_c('v-card-text',[_c('div',{staticClass:"pa-5 text-center"},[_c('v-form',{ref:"formRegistrar",model:{value:(_vm.validRegistrar),callback:function ($$v) {_vm.validRegistrar=$$v},expression:"validRegistrar"}},[_c('b',[_vm._v("Escolha a nova senha")]),_c('v-text-field',{staticClass:"my-2",attrs:{"placeholder":"Senha","outlined":"","dense":"","tile":"","append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPassword ? 'text' : 'password',"rules":[
                            _vm.charsPassword,
                            _vm.lengthPassword,
                            _vm.requirePassword
                          ]},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),_c('b',[_vm._v("Confirme sua senha")]),_c('v-text-field',{staticClass:"mb-5 my-2",attrs:{"placeholder":"Repetir senha","outlined":"","dense":"","tile":"","type":_vm.showConfirmPassword ? 'text' : 'password',"append-icon":_vm.showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off',"rules":[
                            _vm.requirePassword,
                            _vm.matchPassword
                          ]},on:{"click:append":function($event){_vm.showConfirmPassword = !_vm.showConfirmPassword}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('v-btn',{attrs:{"depressed":"","block":"","large":"","color":"primary","dark":"","rounded":""},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Recuperar ")])],1)])],1)],1)],1)])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }