<template>
  <v-card width="100%" tile height="100%" class="pa-0 ma-0">
    <v-container fluid class="pa-0" fill-height>
      <v-row align="stretch" class="fill-height">
        <v-col cols="12" md="5" class="text-center pa-0" order="2" order-md="2" v-if="!$vuetify.breakpoint.mobile">
          <v-card color="primary" tile height="100%" class="pa-10 mx-auto" flat dark>
            <v-row class="fill-height ma-0" justify="center">
              <v-col>
                <h1>Gestão de Privacidade e Segurança de Dados</h1>
                <h3>
                  Adeque sua empresa à Lei Geral de Proteção de Dados (LGPD)
                </h3>
                <img src="@/assets/lgpd_digisystem_ibm.png" style="max-width:100%;" />
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col>
        <!-- <v-col cols="12" md="7" order="2" order-md="2"> -->
          <v-row class="fill-height" justify="center">
            <v-col>
              <div class="px-10">
                <div class="text-center pa-5">
                  <img src="@/assets/logo.png" width="400px" style="max-width:80%;" />
                </div>
                <v-row justify="center" >
                  <v-col cols="12" xs="12" sm="12" md="12" lg="10" xl="10" xxl="10">
                    <v-card-title class="text-center">
                      <h2 class="headline">Recuperação de Senha</h2>
                    </v-card-title>
            
                    <v-card-text>
                      <!-- <v-form ref="formRegistrar" v-model="validRegistrar">
                        <b>Digite seu e-mail</b>
                        <v-text-field
                          v-model="user.email"
                          placeholder="Email"                
                          outlined
                          tile
                          dense
                          :rules="emailRules"                
                          class="my-2"
                        ></v-text-field>
                      </v-form> -->
                      <div class="pa-5 text-center">
                        <v-form ref="formRegistrar" v-model="validRegistrar">
                          <b>Escolha a nova senha</b>
                          <v-text-field
                            v-model="user.password"
                            placeholder="Senha"
                            outlined
                            dense
                            tile
                            class="my-2"
                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="showPassword ? 'text' : 'password'"
                            @click:append="showPassword = !showPassword"
                            :rules="[
                              charsPassword,
                              lengthPassword,
                              requirePassword
                            ]"
                          ></v-text-field>

                          <b>Confirme sua senha</b>
                          <v-text-field
                            v-model="password"
                            placeholder="Repetir senha"
                            outlined
                            dense
                            tile
                            class="mb-5 my-2"
                            :type="showConfirmPassword ? 'text' : 'password'"
                            :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="showConfirmPassword = !showConfirmPassword"
                            :rules="[
                              requirePassword,
                              matchPassword
                            ]"
                          ></v-text-field>
                        </v-form>

                        <v-btn
                          depressed
                          block
                          large
                          color="primary"
                          dark
                          @click="save()"
                          rounded
                        >
                          Recuperar
                        </v-btn>
                      </div>                            
                    </v-card-text>
                  </v-col>
                </v-row>     
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import axios from 'axios'
import { baseApiUrl } from '@/global'

export default {
  name: 'Registrar',
  computed: {
    matchPassword () {
      return () =>
        (this.user.password && this.user.password === this.password) ||
        'As senhas não são iguais.'
    },
    requirePassword () {
      return () =>
        (this.user.password && this.user.password !== '') ||
        'O campo é necessário.'
    },
    charsPassword () {
      const rule =
        /\S*(\S*([a-z]\S*[A-Z]\S*[0-9])|([a-z]\S*[0-9]\S*[A-Z])|([0-9]\S*[a-z]\S*[A-Z])|([0-9]\S*[A-Z]\S*[a-z])|([A-Z]\S*[0-9]\S*[a-z])|([A-Z]\S*[a-z]\S*[0-9]))\S*/
      return () =>
        (this.user.password && rule.test(this.user.password)) ||
        'A senha precisa conter números, letras maiscúlas e minúsculas, e caracteres'
    },
    lengthPassword () {
      return () =>
        (this.user.password && this.user.password.length >= 8) ||
        'A senha precisa ter no mínimo 8 caracteres..'
    }
  },
  watch: {
    password () {
      this.$refs.formRegistrar.validate()
    }
  },
  data: () => ({
    useer: [], 
    tokenn: '',
    form: false,
    terms: false,
    showPassword: false,
    showConfirmPassword: false,
    user: {},
    error: false,
    objects: {},
    password: null,
    validRegistrar: false,
  }),
  methods: {
    tokenValidations() {
      this.tokenn = this.$route.params.token
      const url = `${baseApiUrl}/updatePassword/${this.tokenn}`
      axios
        .get(url)
        .then(() => {
        })
        .catch((error) => {
          if (error) {
          this.$store.dispatch('setErrorInfo', {
            open: true,
            text: 'Ops, parece que seu token expirou',
            route: '/login'
          })
        }
        })
    },

    save () {
      this.$refs.formRegistrar.validate()
      this.tokenn = this.$route.params.token

      if (!this.validRegistrar) {
        this.$store.dispatch('setErrorInfo', {
          open: true,
          text: 'Ops, temos algum probleminha. Cheque os campos pra ver se tudo foi preenchido corretamente.'
        })
        this.$refs.formRegistrar.validate()
        return
      }

      const url = `${baseApiUrl}/updatePassword/${this.tokenn}`
      const users = this.user
      axios
        .put(url, users)
        .then(() => {
          this.$store.dispatch('setErrorInfo', {
            open: true,
            text: 'Tudo Certo! Hora de Logar!',
            route: '/login'
          })
        })
        .catch(() => {
          this.$store.dispatch('setErrorInfo', {
            open: true,
            text: 'Ops, alguns campos não foram preenchidos corretamente. Você poderia revisar se tudo foi preenchido?'
          })
        })
    }
  },
  mounted () {
    this.tokenValidations()
  }
}
</script>
